<template>
  <div>
    <NuxtPage />
    <slot />
    <CookieConsent />
    <AgentComponent>
      <LazyAgentCall />
      <LazyTranslationTools />
    </AgentComponent>
  </div>
</template>
